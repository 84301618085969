import { makeStyles, Typography, Box } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import background from "../../images/pictures/cover.png"
import { Parallax, Background } from "react-parallax"

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "1440px",
    margin: "128px auto",
    [theme.breakpoints.down("md")]: {
      margin: "64px auto",
    },
  },
  heroImage: {
    transition: "all 200ms ease",
    position: "absolute !important",
    zIndex: "-1",
    height: "700px",
    width: "100%",
    right: 0,
    [theme.breakpoints.down("md")]: {
      height: "500px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "300px",
    },
  },
  titleTexts: {
    width: "50%",
    maxWidth: "700px",
    display: "flex",
    zIndex: "2",
    flexDirection: "column",
    justifyContent: "center",
    height: "700px",
    background: "#000a",
    backdropFilter: "blur(10px)",
    [theme.breakpoints.down("md")]: {
      height: "500px",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "32px",
      height: "300px",
    },
    padding: "64px",
  },
  mainTitle: {
    color: "#f45622",
  },
  parallax: {
    width: "100%",
    top: "0",
    height: "700px",
    [theme.breakpoints.down("md")]: {
      height: "500px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "300px",
    },
  },
}))

const WorkHero = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        worksPage {
          title
          subtitle
          banner {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  src
                }
              }
            }
          }
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <Box className={classes.titleTexts}>
        <Typography
          variant="h1"
          className={classes.mainTitle}
          style={{
            marginBottom: "16px",
          }}
        >
          {data.strapi.worksPage.title}
        </Typography>
        <Typography variant="h4">{data.strapi.worksPage.subtitle}</Typography>
      </Box>
      <Box style={{ position: "absolute", width: "100%", height: "100%" }}>
        <Parallax
          strength={500}
          bgImage={background}
          className={classes.parallax}
        >
          <Background className="custom-bg">
            <img src={background} alt={data.strapi.worksPage.title} />
          </Background>
        </Parallax>
      </Box>
    </Box>
  )
}

export default WorkHero
